import ReactHtmlParser from "react-html-parser";
import { useRedirect, useMedia } from "hooks";
import { useLocal } from "contexts/provider";
import website from "data/website";
import { APP } from "data/consts";

function getLocalizedContent(path) {
  const keys = path.split(".");
  let result = website;

  for (let key of keys) {
    result = result[key];
  }

  if (result === "undefined") return "";
  if (typeof result === "string") return parseToReactElement(result);
  if (Array.isArray(result)) {
    return result.map((item) => {
      if (typeof item === "object" && item !== null) {
        const newItem = {};
        Object.keys(item).forEach((key) => {
          newItem[key] =
            typeof item[key] === "string"
              ? parseToReactElement(item[key])
              : item[key];
        });
        return newItem;
      } else if (typeof item === "string") {
        return parseToReactElement(item);
      } else {
        return item;
      }
    });
  }

  return result;
}

function parseToReactElement(result) {
  if (!result) return "";
  return ReactHtmlParser(result?.replace(/\[BUP\]/g, APP.alias));
}

function useSearchParams() {
  return new URLSearchParams(
    typeof window !== "undefined" ? window.location.search : ""
  );
}

export function withContent(Component) {
  const redirect = useRedirect();

  return function ContentComponent(props) {
    const { config } = useLocal();
    const screen = useMedia();

    return (
      <Component
        {...props}
        config={config}
        redirect={redirect}
        screen={screen}
        website={{ get: getLocalizedContent, parseToReactElement }}
      />
    );
  };
}

export { getLocalizedContent, parseToReactElement, useSearchParams };
