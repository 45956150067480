"use client";

import { Stack, Video } from "components";
import { useMedia } from "hooks";

export default function PartnerVideo() {
  const { mobile } = useMedia();

  const base = "https://www.youtube.com/embed/";
  const videos = ["8IE8HIG3mH8", "8IE8HIG3mH8"];

  return (
    <Stack
      className="BupPartner-video"
      flexDirection="row"
      justifyContent="center"
      compontn="section"
      useFlexGap
    >
      <Video
        title="Alianza Banco de Chile"
        src={`${base}${!mobile ? videos[0] : videos[1]}`}
        reel={mobile}
      />
    </Stack>
  );
}
